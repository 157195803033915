@import 'react-rg-designsystem/style/variables.scss';

.BrandToolbarDesktop {
  position: relative;

  left: 0px;
  height: 100%;

  .RGSidebarLeftList {
    height: 100%;
  }
}
