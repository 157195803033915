.ConfigurationActions {
  margin-top: -4px;
  margin-bottom: -4px;

  // width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .price {
    padding-bottom: 60px;
    text-align: left;

    h4, h2 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .RGButton {
    width: 200px;
  }

  .ContractDocument {
    display: none;
  }
  @media print {
    .ContractDocument {
      display: block;
    }
  }
}