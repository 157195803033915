.ErrorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;

    & .card {
        position: relative;
        top: -10%;
        max-width: 450px;
        background-color: white;
        padding: 20px 40px;
        text-align: center;

        & h1 {
            font-size: 36px;
            color: #7164b4;
            margin: 0 0 20px 0;
        } 
        
        & .message {
            font-size: 18px;
            line-height: 1.2;
            color: gray;
            margin: 0 0 20px 0;
        }

        & .suggestions {
            font-size: 16px;
            font-weight: normal;
            color: gray;
            text-align: left;
            margin: 0 0 30px 20px;
            padding: 0 0 0 20px;
        }

        & .error-details {
            font-size: 14px;
            color: rgb(63, 63, 63);
            text-align: left;
            padding: 20px;
            border-radius: 5px;
            background-color: rgb(239, 239, 239);
        }
    }
}