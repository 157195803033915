@import 'react-rg-designsystem/style/variables.scss';

.ConfiguratorList {
  
  .configuratorSelection {
    .children {
      > * {
        margin-right: 20px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    >.RGButton  {
      margin-left: auto;
      margin-right: auto;

      min-width: 400px !important;

      // .link {
      //   width: 60px;
      // }
    }
  }
}