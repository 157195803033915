@import "react-rg-designsystem/style/variables.scss";

html {
  overflow: auto;
}

.Editor {
  background-color: white;

  .RGContentSection {
    padding-left: 0px;
    padding-right: 0px;

    > .content > .RGButton {
      margin-left: auto;
      margin-right: auto;
      width: 340px;
    }
  }
  .loading {
    color: $on-surface-high-emph;
  }

  .RGButton.create {
    margin-left: auto;
    margin-right: auto;

    width: 160px;
  }

  .RGFormContainer {
    width: auto;
    min-width: 400px;
    display: inline-block;

    padding: 40px 40px;
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }
}
