html { 
  overflow: auto;
}

.Contract {
  padding-top: 40px;
  padding-bottom: 40px;

  @media print {
    padding: 0px;
  }
}
