@import 'react-rg-designsystem/style/variables.scss';

.MobilePreviews {
  position: relative;
  // margin: 0px 40px;

  overflow-x: auto;
  display: flex;
  flex-direction: row;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.0) 20%);
  
  .wrapper {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: auto;
    gap: 20px;
  }

  .MobilePreview {
    position: relative; 

    flex-grow: 0;
    flex-shrink: 0;
    height: 940px;
    
    margin-bottom: 20px;
    &:first-child {
      margin-left: 80px;
    }
    &:last-child {
      margin-right: 80px;
    }

    > img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
    > .header {
      position: absolute;
      left: 10px;
      top: 101px;

      box-sizing: border-box;
      height: 52px;
      width: 414px;
      
      background-color: $elevation-zero-base;
      padding-left: 40px;

      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {}
    }
    .SceneView {
      position: absolute;
      left: 10px;
      top: 154px;
      width: 414px;
      height: 455px;

      .swiper-slide {
        height: 455px !important;
      }
    }
  }
}