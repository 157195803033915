@import './utility.scss';

.SceneRenderer {
  position: absolute;
  width: 100%;
  height: 100%;
  
  .stack,
  .layer {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .blendLine {
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0px;
    z-index: 10;
    
    background-color: $elevation-minus1-area;
  }
    
  @include SceneImage; 

  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     object-position: 50% 50%;
      
  //     color: $on-surface-high-emph;
  //   }
  // }
  
  // &.default {}
  // &.floorplan {
  //   .layer img {
  //     object-fit: contain;
  //   }
  // }
}