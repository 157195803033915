@import 'react-rg-designsystem/style/variables.scss';

@mixin SceneImage {
  .sceneImage {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  
  .placeholderImage {
    path {
      stroke: $on-elevation-high-emph;
      stroke: $on-elevation-med-emph-light;
    }
  }
  
  &.default {}
  &.floorplan {
    .sceneImage {
      object-fit: contain;
    }
  }
}

@mixin MobileBottomPanelOnboarding {
  .bottomPanel {
    padding-left: 40px;
    padding-right: 40px;
    background-color: $elevation-zero-base;

    .ToolbarHeader {
      .seperator {
        display: none;
        }
        h3 {
        margin: 0px;
        }
        .RGFormSection,
        .description {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .optionContainer {
      box-sizing: border-box;

      width: calc(100% + 80px);
      margin-left: -40px;
      margin-right: -40px;
      padding: 4px 40px 10px;

      background-color: $elevation-minus1-area;
    }

    .buttons {
      width: 100%;

      margin-top: 10px;
      margin-bottom: 20px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: #{20px - (2 * 4px)};
    }
  }
}