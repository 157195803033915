.QRCode {
    
}

@-moz-document url-prefix() {
  .QRCode {
    // Hide background on firefox
    > svg > path:nth-child(1) {
      display: none;
    }
  }
}
