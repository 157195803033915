@import 'react-rg-designsystem/style/variables.scss';
@import './variables.scss';
@import './utility.scss';

.SceneView {
  position: relative;
  height: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: $elevation-minus1-area;
  }

  @include SceneImage;

  // .sceneImage {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   object-position: 50% 50%;
  // }
  
  // &.default {}
  // &.floorplan {
  //   .sceneImage {
  //     object-fit: contain;
  //   }
  // }
}
