@import 'react-rg-designsystem/style/variables.scss';

.SceneListMobile {
  height: 100%;

  display: flex;
  flex-direction: column;

  .sceneWrapper {
    position: relative;
    height: 100%;
    
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    flex-shrink: 1;
  
    .SceneView {
      position: relative;
      width: 100%;
  
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
  
  .pageDropdown {
    background-color: $elevation-zero-base;
  
    padding: 0px 18px 0px 20px;

    &.high-emph {
      border-bottom: 2px solid var(--primary-zero)
    }
  }
}
