.OpenCodeScreen {
  z-index: 10;
  
  .RGFormContainer {
    max-width: 400px;

    .RGButton {
      margin-top: 10px;
      margin-left: auto;
    }
  }
}