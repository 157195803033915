@import 'react-rg-designsystem/style/variables.scss';
@import './utility.scss';

.OfflineScreen {
  width: 100vw;
  height: 100%;
  overflow: hidden;

  display: flex;

  .BrandToolbarDesktop {
    color: $on-elevation-high-emph;

    .RGFormSection {
      background-color: $elevation-zero-base;
    }
  }

  > .view {
    position: relative;
    flex-grow: 1;

    color: $on-surface-high-emph;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  
    .RGOfflineBlocker {
      .notifyMe {
        margin-top: 20px;
  
        display: flex;
        flex-direction: row;
        gap: 20px;
  
        .RGInputField {
          width: 300px;
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;

    @include MobileBottomPanelOnboarding;
    .bottomPanel {
      padding-bottom: 40px;
    }
  }
  &.desktop {
    flex-direction: row;

  }
}