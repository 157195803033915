.ConfiguratedScenes {
  position: relative;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  max-width: 740px;
  height: 400px;
  overflow: hidden;

  .SceneView {
    width: 100%;
    height: 100%;
  }
}