@import 'react-rg-designsystem/style/variables.scss';

.VariantList {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;

  > :first-child {
    // Desktop and mobile flip the components
    margin-bottom: 16px;
  }
  
  .descriptions {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .RGChipScroller {
    margin-top: 0px;
    padding: 2px 20px 2px;
    gap: 4px;
    flex-grow: 0;
  }

  .thumbs.desktop {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 4px;
  }  
}

.desktop .VariantButton {
  width: 48px;
  height: 48px;


} 
.mobile .VariantButton {
  flex-grow: 0;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
} 
