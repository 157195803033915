.PriceDataDownload {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;

  & .priceDataDownload {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: var(--on-elevation-med-emph-bold);
    }

    & .RGButton {
      width: 200px;
    }
  }

  & .spinning-wheel {
    display: none;
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid var(--primary-zero); /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;

    &.show {
      display: block;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
