@import 'react-rg-designsystem/style/variables.scss';

.Cart {
  position: relative;
  margin: 0px auto 40px;
  text-align: left;
  
  width: 620px;

  &.slim {
    h4 {
      margin-bottom: 13px;
    }
    table {
      width: calc(100% + 80px);
      margin-left: -40px;
      margin-right: -40px;
    }
  
    tr.CartOption td {
      background-color: $elevation-zero-base;
      padding: 12px 0px 14px;

      &:first-child {
        padding-left: 40px;
      }
      &:last-child {
        position: relative;
        padding-right: 40px;
        vertical-align: bottom;

        // >* {
        //   position: absolute;
        //   bottom: 0px;
        // }
      }
    }
    table, tr {
      border-top: 1px solid $elevation-minus2-field;
      border-bottom: 1px solid $elevation-minus2-field;
      border-collapse: collapse;
    }

    .CartTotal p {
      width: 100%;
    }
  }
}

.mobile .Cart {
  width: 100%;
}
