.ConfigurationOverview {
  width: 100%;
  // display: flex;
  // flex-direction: row;  
  // flex-wrap: wrap;
  // justify-content: center;

  .RGBottomCard .details {
    // border-radius: 8px 8px 0px 0px;

    ::-webkit-scrollbar-track {
      margin-top: 60px;
    }
    
    .content {
      // box-sizing: border-box;
      // margin-top: 20px;
      // padding: 20px 40px 40px;

      h2 {
        text-align: left;
        margin: 0px;
      }
    }
  }

  &.full {
    .ConfigurationActions {
      position: fixed;
      left: 50%;
      transform: translateX(435px);
      top: 259px;

      width: 220px;

      > .RGButton {
        margin-left: 0px;
        margin-right: 0px;
        width: 220px;
      }
    }
  }
}